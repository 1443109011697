<!-- Header Section -->
    <header>
        <div>
           <nav class="navbar navbar-expand-md">
              <!-- Brand -->
              <a class="navbar-brand spa-navbar-brand-img" [routerLink]="['/']">
                <img src="assets/images/insgeek.png">
              </a>
              
              <!-- Toggler/collapsibe Button -->
              <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span class="navbar-toggler-icon"><img src="assets/images/toggle-icon.svg" (click)="MenuStateValues.open()"></span>
              </button> -->

              <!-- Navbar links -->
              <div class="collapse navbar-collapse custom-menu" [class.custom-menu-open]="MenuStateValues.getIsOpen()">
                 <button class="menu-close-btn" (click)="MenuStateValues.close()">&#10005;</button>
                 <ul class="navbar-nav">
                    <!-- <li class="nav-item"><a class="nav-link" [routerLink]="['/underwriting']">Underwriting</a></li> -->
                    <li class="nav-item"><a class="nav-link" [routerLink]="['/about-us']">About Us</a></li>
                    <!-- <li class="nav-item"><a class="nav-link" [routerLink]="['/do-i-qualify']">Do I Qualify?</a></li> -->
                 </ul>
              </div>
           </nav>
        </div>
    </header>
     <!-- form Section -->
     <section class="banner-section" id="home-banner">
        <div class="content">
           <h2 class="animated bounceInLeft" [innerHTML]="page_title"></h2>
           <p class="spa-home-learn-more animated bounceInLeft">{{ sub_text }}</p>
        </div>
        <div class="form-content-main">
           <div class="gravity_section">
                <div class="container-fluid">
                    <div class="gf_browser_gecko gform_wrapper" id="gform_wrapper_4">
                        <div id="gform_4">
                            <div class="gform_body">
                                <ul id="gform_fields_4" class="gform_fields top_label form_sublabel_below description_below">
                                    <!-- <li id="field_4_3" class="gfield field_sublabel_below field_description_below gfield_visibility_visible">
                                        <label class="gfield_label" for="input_4_3">Zip Code</label>
                                        <div class="ginput_container ginput_container_number" [class.invalid]="!zipCode.value && !validZip">
                                            <input name="zipcode" (change)="FormValues.setZipCode($event.target.value)" [value]="FormValues.getZipCode()" type="text" maxlength="5" class="medium" placeholder="Zip Code" #zipCode>
                                        </div>
                                    </li> -->
                                    <li id="field_4_2" class="gfield gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible">
                                        <label class="gfield_label gfield_label_before_complex" for="input_4_2_1">Date of birth</label>
                                        <div id="" class="ginput_container ginput_container_date">
                                            <div class="clear-multi" [class.invalid]="!dateInput.value && !validDate">
                                                <div class="gfield_date_day ginput_container spa-date-input-dd" id="input_4_2_2_container">
                                                    <input type="text" name="DOB"  pattern="[0-9]*" (change)="FormValues.setDob($event.target.value)" [value]="FormValues.getDob()" mask='00/00/0000' placeholder="MM/DD/YYYY" #dateInput>
                                                    <label>MM/DD/YYYY</label>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <div class="gform_footer top_label"> 
                                   <button type="button" id="gform_submit_button_4" class="gform_button button" (click)="gotoSpa(dateInput)">Go</button> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
     </section>

     <section>
        <div class="modal-bg modal" id="ouibounce-modal" #modal 
            [class.hide-modal]="MenuStateValues.getIsOpen()">
            <div class="donot-leave-popup">
                <button class="modal-close-btn" (click)="MenuStateValues.open()">&#10005;</button>
                <div class="d-flex flex-wrap donot-leave-popup-container">
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                    <div class="exit-popup-title">
                        <h2>IS A LIRP RIGHT FOR <br />YOU? LET'S FIND OUT!</h2>
                    </div>
                    <div class="col-xl-12 col-12 px-0">
                        <ul class="intent-popup-form-container">
                            <!-- <li class="">
                                <label class="popup-intent-label" for="input_4_3">Zip Code</label>
                                <div class="input-invalid popup-input-container" [class.invalid]="!zipCode.value && !validZip">
                                    <input name="zipcode" (change)="FormValues.setZipCode($event.target.value)" [value]="FormValues.getZipCode()" type="text" maxlength="5" class="medium" placeholder="ZIP CODE" #zipCode>
                                </div>
                            </li> -->
                            <li class="">
                                <label class="popup-intent-label">Date of birth</label>
                                <div class="input-invalid popup-input-container" [class.invalid]="!dateInput.value && !validDate">
                                    <input type="text" name="DOB" (change)="FormValues.setDob($event.target.value)" [value]="FormValues.getDob()" mask='00/00/0000' placeholder="MM/DD/YYYY" #dateInput>
                                </div>
                            </li>
                            <div class="popup-submit-button-container"> 
                            <button type="button" class="intent-popup-submit-button" (click)="gotoSpa(dateInput)">Get quotes now</button> 
                            </div>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 ml-5 popup-health-image">
                    <img src="assets/images/home-popup-phone.jpg">
                </div>
                </div>
            </div>
        </div>
     </section>