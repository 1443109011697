import { Injectable } from '@angular/core';
import { 
  UTM_URL 
} from './api.urls';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UTM } from '../models/Utm.model';


@Injectable({
  providedIn: 'root'
})
export class UtmService {
  public UtmResponseData: any = null;

  private _httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };
  constructor(private _http: HttpClient) { }

  SaveUtmData(body:UTM) {

    let bodyString = JSON.stringify(body); 

    return this._http.post( UTM_URL , bodyString ,{
        headers: this._httpOptions.headers,
      }) 
  }

  SendUtmData(UtmData: UTM){
    this.SaveUtmData(UtmData).subscribe(
      res => {
        this.UtmResponseData = res;
        
        
      }
    )
  }

}
