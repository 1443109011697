import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-immediate-annuities',
  templateUrl: './immediate-annuities.component.html',
  styleUrls: ['./immediate-annuities.component.css']
})
export class ImmediateAnnuitiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scroll(0,0);
  }

}
