<footer class="footer">
        <div class="container">
            <div class="footer_nav">
                <ul>
                    <li><a href="https://www.locallifeagents.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
                    <li><a href="https://www.locallifeagents.com/terms-of-use/" target="_blank">Terms & Conditions</a></li>
                </ul>
            </div>
            <div class="copywrite">© {{currentYear}} Insurance Geek, LLC. All Rights Reserved.</div>
            <div class="instro">
                <p>This is not a Guaranteed Issue Policy. All quotes are subject to underwriting. The final premium and acceptance will 
                    be determined after the underwriting review has been completed. Insurance Geek is the principal 
                    Agency and writing Agent of Record.<br></p>
                <!-- The spa multi-year guarantee and immediate annuity products, form numbers GB-SPIA-01 and GB-MYGA-01, respectively, or variations of 
                such, are issued by Guggenheim Life and Annuity Company (d/b/a Guggenheim Life and Annuity Insurance Company in California; NAIC#83607) 
                ("Guggenheim Life"), a Delaware-domiciled insurance company with its principal office in Indianapolis, Indiana. spa annuities 
                and/or certain features may not be available in all states. Guggenheim Life is currently licensed and authorized to do business in 49 
                states (all states except New York), the District of Columbia and Puerto Rico.<br>
                Annuities contain limitations, early termination charges and adjustments. Your spa annuity may be subject to Surrender or 
                Commutation Charges and a Market Value Adjustment. For more information about these limitations and other product features, please review 
                the Multi-Year Guarantee Annuity (MYGA) Product Summary or Single Premium Immediate Annuity (SPIA) Product Summary available in the About 
                Us Section.</p> -->
            </div>
        </div>
    </footer>