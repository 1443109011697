import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule} from "@angular/common/http";

import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  UnderwritingComponent,
  AboutusComponent,
  WhoWeAreComponent
} from './pages';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { ImmediateAnnuitiesComponent } from './pages/immediate-annuities/immediate-annuities.component';
import { SpaFormComponent } from './pages/spa-form/spa-form.component';
import { NgxMaskModule } from 'ngx-mask';
import { AppGlobals } from './models/AppGlobals';
import { LeadFormModel } from './models/LeadForm';
import { DoIQualifyFormModel } from './models/DoIQualifyForm';
import { MenuState } from './models/MenuState';
import { ShowResults } from './models/ShowResults';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ResultComponent } from './pages/result/result.component';
import { DoIQualifyComponent } from './pages/do-i-qualify/do-i-qualify.component';
import { ApplyComponent } from './pages/apply/apply.component';
import { SuccessComponent } from './pages/success/success.component';
import { DoIQualifyCarrier } from './models/DoIQualifyCarrier';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { LaddaModule } from 'angular2-ladda';
import { ConditionalModalComponent } from './layouts/modal/conditional-modal/conditional-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    AppComponent,
    UnderwritingComponent,
    AboutusComponent,
    WhoWeAreComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ImmediateAnnuitiesComponent,
    SpaFormComponent,
    ResultComponent,
    DoIQualifyComponent,
    ApplyComponent,
    SuccessComponent,
    ConditionalModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    ScrollToModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    HttpClientModule,
    GooglePlaceModule,
    LaddaModule,
  ],
  providers: [
    AppGlobals,
    LeadFormModel,
    DoIQualifyFormModel,
    MenuState,
    ShowResults,
    DoIQualifyCarrier
  ],
  entryComponents: [ConditionalModalComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
