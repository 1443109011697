<header>
      <nav class="navbar spa-main-nav navbar-expand-md">
         <!-- Brand -->
         <a class="navbar-brand spa-navbar-brand-img" [routerLink]="['/']">
              <img src="assets/images/insgeek.png">
         </a>
      </nav> 
</header>

<div class="page">
    <div class="container-fluid">
       <div class="ryhq4q0_e296pg">
          <div class="row">
             <div class="spa-sidenav col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mt-5">
                <div class="side">
                   <ul class="navbar-nav">
                      <li class="nav-item"><a class="nav-link" [ngx-scroll-to]="'#productDetails'">Product Details</a></li>
                      <li class="nav-item"><a class="nav-link" [ngx-scroll-to]="'#theProcess'">The Process</a></li>
                      <li class="nav-item"><a class="nav-link" [ngx-scroll-to]="'#buildChart'">Build Charts</a></li>
                   </ul>
                </div>
             </div>
             <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 mt-5 wow fadeInRight" data-wow-delay="0.2s">
                <div class="product_etails" id="productDetails">
                   <h2>WHAT IS ACCELEWRITING ® ?</h2>
                   <p>Sagicor’s Accelewriting® process is an automated underwriting system that utilizes an
                     eApplication to provide an underwriting decision in minutes with no telephone interview,
                     medical exams, bodily fluids or Attending Physician Statement (APS) required. 1
                     Accelewriting® with eDelivery is available on Sagicor’s Sage Term Life Insurance product:</p>
                     <h4>Face Amounts:</h4>
                     <p>Minimum Face Amount: $50,000</p>
                     <p>Maximum Face Amount: $1,000,000</p>
                     <h4>Issue Ages:</h4>
                     <ul>
                        <ol>18 years – 45 years up to $1,000,000 (available with 10, 15 and 20 year term)</ol>
                        <ol>46 years – 55 years up to $750,000 (available with 10, 15 and 20 year term)</ol>
                        <ol>56 years – 65 years up to $500,000 (not available with 20 year term - Tobacco)</ol>
                     </ul>
                     <h4>Risk Classes:</h4>
                     <ul>
                        <ol>Preferred Plus Non-Tobacco / Preferred Plus Tobacco</ol>
                        <ol>Preferred Non-Tobacco / Preferred Tobacco</ol>
                        <ol>Standard Non-Tobacco / Standard Tobacco</ol>
                        <ol>Rated Non-Tobacco
                           <br>(will be referred to underwriting for review on face amounts of $500,001 and higher)
                        </ol>
                        <ol>Rated Tobacco / Rated 2 Non-Tobacco / Rated 2 Tobacco
                           <br>(will be referred to underwriting for review and only available for face amounts of
                           $500,001 and higher)
                        </ol>
                     </ul>
                     <h4>Conversion Program</h4>
                        <p>Conversion credits available in years 2 -5</p>
                     <h4>Inherent Rider:</h4>
                     <p>Accelerated Benefit Insurance Rider for Terminal Condition or
                     Nursing Home Confinement <sup>2</sup></p>
                     <h4>Optional Riders:</h4>
                     <p>• Accidental Death Benefit Rider</p>
                     <p>• Children’s Term Rider</p>
                     <p>• Waiver of Premium Rider</p>
                     <p>Sage Term is available for other issue ages and face amounts with our fully underwritten
                     application.</p>
                </div>

                <div class="the-process" id="theProcess">
                  <h2>ACCELEWRITING ® PROCESS FOR SAGE TERM LIFE</h2>
                  <div class="theProcess-details mt-5">
                     <p>Sagicor’s Accelewriting® process for Sage Term is available for applications up to $1,000,000,
                        subject to age limits</p>
                     <p>On face amounts greater than $500,000 ONLY, we will offer automated underwriting, with
                        the opportunity for a decision in minutes, on Preferred Plus, Preferred and Standard classes
                        (non-tobacco and tobacco). Substandard risks (Rated and Rated 2) will be reviewed by
                        underwriting and will not receive an immediate decision. After review, the underwriter will work
                        with you and advise you of what additional requirements, if any, will be needed to make an offer.</p>
                     <p>On face amounts less than $500,000, automated underwriting with a decision in minutes, is
                        available on Preferred Plus. Preferred, Standard and Rated classes. Rated 2 is not available.</p>
                     <p>Accelewriting® uses consumer reports such as pharmaceutical databases, MIB, MVR, public
                        records and credit file information to make an underwriting decision.</p>
                     <h4>FINANCIAL UNDERWRITING</h4>
                     <p><strong>Business Coverage -</strong>  Please provide details such as business valuation, percentage of ownership,
                        number of owners, loan information, etc. to help explain the purpose of coverage.</p>
                     <p><strong>Income Replacement -</strong> See income factor table below. Income factors shown can be subject to
                        underwriting discretion.</p>
                     
                     <div class="process-table mb-5">
                        <p>Income Factor</p>
                        <table>
                           <tr>
                             <td>Under 45</td>
                             <td>20x</td>
                           </tr>
                           <tr>
                              <td>46-55</td>
                              <td>15x</td>
                            </tr>
                            <tr>
                              <td>56-60</td>
                              <td>10x</td>
                            </tr>
                            <tr>
                              <td>61-65</td>
                              <td>5x</td>
                            </tr>
                        </table>
                     </div>
                     <p><strong>Non-Working Spouse -</strong> We may allow equal coverage of the working spouse, up to $1,000,000.
                        Please provide total coverage on working spouse.</p>
                     <h4>NON-MEDICAL RISKS</h4>
                     <p><strong>Criminal History -</strong> Applicants must be off probation or parole for over 12 months and not have
                        any pending charges to be considered.</p>
                     <p><strong>Foreign Travel (subject to state law) -</strong> We will consider applicants traveling to foreign countries,
                        but certain restrictions apply. Purpose of travel, duration and destination will be required.</p>
                     <p><strong>Residency -</strong>We will consider U.S. citizens and U.S. permanent residents for coverage. If the
                        applicant is a permanent resident, the alien registration number (USCIS number, A number) must
                        be provided.</p>
                  </div>
                </div>

                <div class="build-chart" id="buildChart">
                  <h2>ACCELEWRITING® UNISEX BUILD TABLE</h2>
                  <table>
                     <tr>
                       <th>Height</th>
                       <th>Minimum</th>
                       <th>Preferred Plus</th>
                       <th>Preferred</th>
                       <th>Standard</th>
                       <th>Rated</th>
                     </tr>
                     <tr>
                       <td>4’8”</td>
                       <td>83</td>
                       <td>115</td>
                       <td>137</td>
                       <td>147</td>
                       <td>186</td>
                     </tr>
                     <tr>
                       <td>4’9”</td>
                       <td>86</td>
                       <td>120</td>
                       <td>142</td>
                       <td>151</td>
                       <td>192</td>
                     </tr>
                     <tr>
                        <td>4’10”</td>
                        <td>89</td>
                        <td>124</td>
                        <td>147</td>
                        <td>155</td>
                        <td>199</td>
                     </tr>
                     <tr>
                        <td>4’11”</td>
                        <td>92</td>
                        <td>128</td>
                        <td>152</td>
                        <td>161</td>
                        <td>206</td>
                     </tr>
                     <tr>
                        <td>5’0”</td>
                        <td>94</td>
                        <td>132</td>
                        <td>157</td>
                        <td>166</td>
                        <td>213</td>
                     </tr>
                     <tr>
                        <td>5’1”</td>
                        <td>98</td>
                        <td>136</td>
                        <td>164</td>
                        <td>172</td>
                        <td>220</td>
                     </tr>
                     <tr>
                        <td>5’2”</td>
                        <td>102</td>
                        <td>141</td>
                        <td>169</td>
                        <td>178</td>
                        <td>227</td>
                     </tr>
                     <tr>
                        <td>5’3”</td>
                        <td>105</td>
                        <td>146</td>
                        <td>174</td>
                        <td>184</td>
                        <td>235</td>
                     </tr>
                     <tr>
                        <td>5’4”</td>
                        <td>108</td>
                        <td>150</td>
                        <td>179</td>
                        <td>190</td>
                        <td>242</td>
                     </tr>
                     <tr>
                        <td>5’5”</td>
                        <td>112</td>
                        <td>155</td>
                        <td>184</td>
                        <td>196</td>
                        <td>250</td>
                     </tr>
                     <tr>
                        <td>5’6”</td>
                        <td>115</td>
                        <td>160</td>
                        <td>189</td>
                        <td>202</td>
                        <td>258</td>
                     </tr>
                     <tr>
                        <td>5’7”</td>
                        <td>119</td>
                        <td>165</td>
                        <td>194</td>
                        <td>208</td>
                        <td>265</td>
                     </tr>
                     <tr>
                        <td>5’8”</td>
                        <td>122</td>
                        <td>170</td>
                        <td>200</td>
                        <td>215</td>
                        <td>273</td>
                     </tr>
                     <tr>
                        <td>5’9”</td>
                        <td>126</td>
                        <td>174</td>
                        <td>205</td>
                        <td>221</td>
                        <td>282</td>
                     </tr>
                     <tr>
                        <td>5’10”</td>
                        <td>129</td>
                        <td>179</td>
                        <td>211</td>
                        <td>228</td>
                        <td>290</td>
                     </tr>
                     <tr>
                        <td>5’11”</td>
                        <td>134</td>
                        <td>184</td>
                        <td>216</td>
                        <td>235</td>
                        <td>298</td>
                     </tr>
                     <tr>
                        <td>6’0”</td>
                        <td>137</td>
                        <td>190</td>
                        <td>223</td>
                        <td>242</td>
                        <td>306</td>
                     </tr>
                     <tr>
                        <td>6’1”</td>
                        <td>141</td>
                        <td>194</td>
                        <td>228</td>
                        <td>248</td>
                        <td>315</td>
                     </tr>
                     <tr>
                        <td>6’2”</td>
                        <td>145</td>
                        <td>200</td>
                        <td>235</td>
                        <td>255</td>
                        <td>324</td>
                     </tr>
                     <tr>
                        <td>6’3”</td>
                        <td>148</td>
                        <td>205</td>
                        <td>241</td>
                        <td>263</td>
                        <td>333</td>
                     </tr>
                     <tr>
                        <td>6’4”</td>
                        <td>152</td>
                        <td>210</td>
                        <td>247</td>
                        <td>270</td>
                        <td>341</td>
                     </tr>
                     <tr>
                        <td>6’5”</td>
                        <td>156</td>
                        <td>216</td>
                        <td>254</td>
                        <td>277</td>
                        <td>350</td>
                     </tr>
                     <tr>
                        <td>6’6”</td>
                        <td>161</td>
                        <td>222</td>
                        <td>261</td>
                        <td>284</td>
                        <td>360</td>
                     </tr>
                     <tr>
                        <td>6’7”</td>
                        <td>165</td>
                        <td>228</td>
                        <td>268</td>
                        <td>292</td>
                        <td>369</td>
                     </tr>
                     <tr>
                        <td>6’8”</td>
                        <td>169</td>
                        <td>234</td>
                        <td>275</td>
                        <td>299</td>
                        <td>378</td>
                     </tr>
                     <tr>
                        <td>6’9”</td>
                        <td>173</td>
                        <td>240</td>
                        <td>282</td>
                        <td>307</td>
                        <td>388</td>
                     </tr>
                     <tr>
                        <td>6’10”</td>
                        <td>178</td>
                        <td>247</td>
                        <td>290</td>
                        <td>315</td>
                        <td>398</td>
                     </tr>
                     <tr>
                        <td>6’11”</td>
                        <td>182</td>
                        <td>253</td>
                        <td>298</td>
                        <td>323</td>
                        <td>408</td>
                     </tr>
                   </table>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>

 <div class="get-quote">
    <button type="button" [routerLink]="['/get-quote']" class="btn get-quote-btn">Get Quote</button>
 </div>