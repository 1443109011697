import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  currentYear: number=null;
  constructor() { }

  ngOnInit() {
    this.currentYear= new Date().getFullYear();
    console.log( this.currentYear);
  }


}
