<header>
   <nav class="navbar spa-main-nav navbar-expand-md">
       <!-- Brand -->
       <a class="navbar-brand spa-navbar-brand-img" [routerLink]="['/']">
           <img src="assets/images/insgeek.png">
       </a>
   </nav> 
</header>
 
 <div class="page">
     <div class="container-fluid">
        <div class="ryhq4q0_e296pg">
           <div class="row">
              <div class="col-sm-3">
                 <div class="side">
                     <ul class="navbar-nav">
                        <li class="nav-item animated_8s slideInDown"><a class="nav-link" [routerLink]="['/annuities']">Our annuities</a></li>
                        <li class="nav-item animated_6s slideInDown"><a class="nav-link" [routerLink]="['/compare']">How we compare</a></li>
                        <li class="nav-item animated_4s slideInDown"><a class="nav-link" [routerLink]="['/immediate-annuities']">Immediate Annuities</a></li>
                        <li class="nav-item animated_2s slideInDown"><a class="nav-link" [routerLink]="['/who-we-are']">Who we are</a></li>
                     </ul>
                 </div>
              </div>
              <div class="col-sm-9 wow fadeInRight" data-wow-delay="0.2s">
                  <div class="immediate_annuities" id="imi_annuities">
                      <h2>Immediate Annuities.</h2>
                      <p>mmediate annuities offer guaranteed, regular monthly income payouts that begin right away for a period of 3 to 10 years. 
                         Immediate annuity income payouts consist of a portion of principal and earned interest. The interest portion is considered 
                         taxable income.
                      </p>
                      <p>Get all the details on withdrawals and fees in the full product summary. Florida residents click here.</p>
                   </div>
              </div>
           </div>
        </div>
     </div>
  </div>