<header>
  <nav class="navbar spa-main-nav navbar-expand-md">
    <!-- Brand -->
    <a class="navbar-brand spa-navbar-brand-img" [routerLink]="['/']">
      <img src="assets/images/insgeek.png">
    </a>
  </nav>
</header>
<!-- Form Section -->
<section class="form-setion-main">
  <div class="spa-form-container">
    <form action="">
      <div class="spa-form-content-0 vh-100 d-flex align-items-center" id="scrollToDob">
        <div class="container">
          <div class="zip-dob">
            <div class="spa-form-zip">
              <label>Date of birth</label>
              <input placeholder="MM/DD/YYYY" (blur)="checkDob()" (change)="FormValues.setDob($event.target.value)"
                [value]="FormValues.Dob" (keyup)="CheckInput($event,'dob')" mask='00/00/0000'
                class="spa-form-date-input spa-dob" [(ngModel)]="FormValues.Dob"
                [ngModelOptions]="{standalone: true}">
              <p class="error-msg" *ngIf="FormErrors != null && FormErrors.dob">*Please enter a valid date of birth.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="spa-form-content-0 vh-100 d-flex align-items-center" id="scrollToTop">
        <div class="container">
          <div class="zip-dob mb-5">
            <div class="spa-form-zip spa-form-address mb-0">
              <label>Enter Address</label>
              <!-- <input  type="number" onKeyPress="if(this.value.length==5) return false;" placeholder="ZIP CODE" name="zipcode" (blur)="checkDobAndZip()" (change)="FormValues.setZipCode($event.target.value)" [value]="FormValues.getZipCode()" (keyup)="CheckInput($event,'zipcode')" class="spa-form-zip-input"> -->
              <input class="spa-form-date-input location-input" 
                placeholder="123 Main St" 
                autocomplete="off" 
                ngx-google-places-autocomplete 
                [options]="{
                  types: [],
                  componentRestrictions: { country: ['us', 'pr', 'vi', 'gu', 'mp'] }
                }" 
                #placesRef="ngx-places" 
                (onAddressChange)="handleAddressChange($event)"
                (input)="CheckAddressInput($event)"
                (blur)="checkZip()" />
              <p class="error-msg" *ngIf="FormErrors != null && FormErrors.zip_code">*Please enter a valid address.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="spa-form-content-0 vh-100 d-flex align-items-center" id="scrollToCarrier">
        <div class="container">
          <div class="zip-dob mb-5">
            <div class="spa-form-zip spa-form-address mb-0">
              <label> Enter Carrier Name</label>
              <input placeholder="Carrier Name" (blur)="checkCarrier()" (change)="FormValues.setCarrierName($event.target.value)"
                [value]="FormValues.CarrierName" (keyup)="CheckInput($event,'carrier_name')"
                class="spa-form-date-input">
              <p class="error-msg" *ngIf="FormErrors != null && FormErrors.carrier_name">*Please enter carrier name.</p>
            </div>
          </div>
        </div>
      </div>


      <!-- <div class="spa-form-content-1 vh-100 d-flex align-items-center" id="scrollToIncome">
        <div class="container">
          <div class="income">
            <div class="spa-form-zip res-mt-5">
              <label>Annual Income</label>
              <span class="income-sign">$</span>
              <input type="text" pattern="[0-9]*" placeholder="USD" name="annual-income"
                [(ngModel)]="FormValues.annualIncome" (blur)="checkIncome()"
                (keyup)="$event.target.value = formatMoney($event.target.value)" maxlength="20"
                class="spa-form-date-input c-pl-5">
            </div>
            <div class="spa-form-zip res-mt-5">
              <div class="desired-retirement-container">
                <label>Desired Retirement Income</label>
                <span class="spa-information-icon information-icon-position"
                  tooltip="How much supplemental income would you like to withdraw annually from the policy?"
                  placement="right">
                  i
                </span>
              </div>
              <span class="income-sign">$</span>
              <input type="text" pattern="[0-9]*" placeholder="USD" name="desired-income"
                (keyup)="$event.target.value = formatMoney($event.target.value)" maxlength="20"
                [(ngModel)]="FormValues.desiredIncome" (blur)="checkIncome()" class="spa-form-date-input c-pl-5">
            </div>
            <p class="error-msg" *ngIf="FormErrors != null && (FormErrors.annual_income || FormErrors.desired_income)">
              *Please enter your annual income and desired retirement income.</p>
          </div>
        </div>
      </div> -->

      <!-- <div class="spa-form-content-2 vh-100 d-flex align-items-center" id="scrollToFaceAmount">
        <div class="container">
          <div class="px-3">
            <div class="spa-headeing d-flex">
              <h3 class="face-amount mr-3">Monthly Contribution Amount</h3>
              <span class="spa-information-icon"
                tooltip="Enter is the amount you would like to contribute monthly. We can adjust the amount $ later, but start with a $ amount you are comfortable investing."
                placement="right">
                i
              </span>
            </div>
            <div>
              <span name="coverageamount" [class.highlight-selected]="FormValues.MonthlyContribution == '250'"
                (click)="selectedFaceAmount('250')" class="spa-money">$ 250&nbsp;&nbsp;</span>
              <span name="coverageamount" [class.highlight-selected]="FormValues.MonthlyContribution == '500'"
                (click)="selectedFaceAmount('500')" class="spa-money">$ 500&nbsp;&nbsp;</span>
            </div>
            <div>
              <span name="coverageamount" [class.highlight-selected]="FormValues.MonthlyContribution == '750'"
                (click)="selectedFaceAmount('750')" class="spa-money">$ 750&nbsp;&nbsp;</span>
              <span name="coverageamount" [class.highlight-selected]="FormValues.MonthlyContribution == '1000'"
                (click)="selectedFaceAmount('1000')" class="spa-money">$ 1000</span>
            </div>
            <div class="p-relative">
              <span name="coverageamount" [class.highlight-selected]="FormValues.MonthlyContribution == '2000'"
                (click)="selectedFaceAmount('2000')" class="spa-money">$ 2000</span>
              <span class="income-sign-other">$</span>
              <input type="text" pattern="[0-9]*" placeholder="Other" maxlength="20"
                (keyup)="$event.target.value = formatMoney($event.target.value)"
                class="spa-money monthly-contribution-other c-pl-45" (blur)="selectedFaceAmount($event.target.value)" /> -->
              <!-- <span name="coverageamount" [class.highlight-selected]="FormValues.CoverageAmount == '2000'" (click)="selectedFaceAmount('2000')" class="spa-money">$ 2000&</span> -->
            <!-- </div>

            <p class="error-msg" *ngIf="FormErrors != null && FormErrors.monthly_contribution">*Please select a value.
            </p>

          </div>
        </div>
      </div> -->
      <!-- Policy type -->
      <div class="spa-form-content-2 vh-100 d-flex align-items-center" id="scrollToPolicyType">
        <div class="container">
          <div class="px-3">
            <div class="spa-headeing d-flex">
              <h3 class="face-amount mr-3">What type of policy do you have?</h3>
              <span class="spa-information-icon" tooltip="Select type of policy." placement="right">
                i
              </span>
            </div>
            <div>
              <span name="policy-type" [class.highlight-selected]="FormValues.PolicyType == 'term_life'"
                class="policy-type" (click)="selectedTermPolicy('term_life')">Term Life&nbsp;&nbsp;</span><br>
              <span name="policy-type" [class.highlight-selected]="FormValues.PolicyType == 'whole_life'"
                class="policy-type" (click)="selectedPermanentPolicy('whole_life')">Whole Life&nbsp;&nbsp;</span>
            </div>
            <div>
              <span name="policy-type" [class.highlight-selected]="FormValues.PolicyType == 'universal_life'"
                class="policy-type" (click)="selectedPermanentPolicy('universal_life')">Universal
                Life&nbsp;&nbsp;</span>
            </div>

            <p class="error-msg" *ngIf="FormErrors != null && FormErrors.policy_type">*Please select an option.
            </p>

          </div>
        </div>
      </div>
      <!--/ Policy type -->
      <!-- Term life -->
      <div class="spa-form-content-5 vh-100 d-flex align-items-center" id="scrollToHiddenPolicyQuestion">
        <div class="container">
          <div class="px-3" *ngIf="hidden_termLife">
            <h3 class="spa-smoke">Does your Term policy have a conversion option?</h3>
            <div>
              <span class="spa-yes-no" [class.highlight-btn]="FormValues.HiddenQuestion == 'no'"
                (click)="conversionOption('no')">NO</span>
              <span class="spa-yes-no" [class.highlight-btn]="FormValues.HiddenQuestion == 'yes'"
                (click)="conversionOption('yes')">YES</span>
            </div>
            <p class="error-msg" *ngIf="FormErrors != null && FormErrors.hidden_policy_question">*Please select an
              option.</p>
          </div>
          <div class="px-3" *ngIf="hidden_wholeUniversal">
            <h3 class="spa-smoke">If it's a permanent policy is there any cash value?</h3>
            <div>
              <span class="spa-yes-no" [class.highlight-btn]="FormValues.HiddenQuestion == 'no'"
                (click)="cashValue('no')">NO</span>
              <span class="spa-yes-no" [class.highlight-btn]="FormValues.HiddenQuestion == 'yes'"
                (click)="cashValue('yes')">YES</span>
            </div>no
            <p class="error-msg" *ngIf="FormErrors != null && FormErrors.hidden_policy_question">*Please select an
              option.</p>
          </div>
        </div>
      </div>
      <!--/ Term life -->
      <!-- Whole life -->
      <!-- <div class="spa-form-content-5 vh-100 d-flex align-items-center" id="scrollToHiddenPolicyQuestion" >
        <div class="container">
          
        </div>
      </div> -->
      <!--/ Whole life -->
      <!-- Benefits -->
      <div class="spa-form-content-3 vh-100 d-flex align-items-center" id="scrollToDeathBenfits">
        <div class="container">
          <div class="occupation">
            <div class="spa-form-zip">
              <label>How much is the death benefit of the policy?</label>
              <div class="spa-select-Grp">
                <input [(ngModel)]="FormValues.DeathBenefit" (change)="selectedDeathBenifit()"
                  [ngModelOptions]="{standalone: true}" pattern="[0-9]*" mask='00,00,0000' 
                  placeholder="Death Benifit"
                  (keyup)="$event.target.value = formatMoney($event.target.value)"
                  class="spa-form-date-input responsive-text-1" style="font-size: 48px; padding: 7px 0px;">
              </div>
            </div>
            <p class="error-msg" *ngIf="FormErrors != null && FormErrors.death_benifits">*Please enter your Benefit
              amount.</p>
          </div>
        </div>
      </div>
      <!-- Benefits -->
      <!-- <div class="spa-form-content-3 vh-100 d-flex align-items-center" id="scrollToOccupation">
        <div class="container">
          <div class="occupation">
            <div class="spa-form-zip">
              <label>Occupation</label>
              <div class="spa-select-Grp">
                <input [(ngModel)]="FormValues.occupation" (change)="selectedOccupation()"
                  [ngModelOptions]="{standalone: true}" placeholder="Enter"
                  class="spa-form-date-input responsive-text-1" style="font-size: 48px; padding: 7px 0px;">
              </div>
            </div>
            <p class="error-msg" *ngIf="FormErrors != null && FormErrors.occupation">*Please enter your occupation.</p>
          </div>
        </div>
      </div>
      <div class="spa-form-content-3 vh-100 d-flex align-items-center" id="scrollToAgeToTakeIncome">
        <div class="container">
          <div class="AgeToTakeIncome">
            <div class="spa-form-zip">
              <label>Age you would like to start taking income</label>
              <div class="spa-select-Grp">
                <input [(ngModel)]="FormValues.AgeToTakeIncome" pattern="[0-9]*" (change)="selectedAgeToTakeIncome()"
                  [ngModelOptions]="{standalone: true}" placeholder="Enter Age"
                  class="spa-form-date-input responsive-text-1" style="font-size: 48px; padding: 7px 0px;">
              </div>
            </div>
            <p class="error-msg" *ngIf="FormErrors != null && FormErrors.age_to_take_income">*Please enter a value.</p>
          </div>
        </div>
      </div> -->
      <!-- <div class="spa-form-content-3 vh-100 d-flex align-items-center" id="scrollToBilling">
               <div class="container">
                  <div class="px-3 p-8rem">
                     <h3 class="spa-gender">Billing method</h3>
                     <div>
                        <span [class.highlight-btn]="FormValues.Billing == 'monthly'" (click)="selectedBillingMethod('monthly')" class="spa-yes-no">Monthly</span>
                        <span [class.highlight-btn]="FormValues.Billing == 'annual'" (click)="selectedBillingMethod('annual')" class="spa-yes-no">Annual</span>
                     </div>
                  </div>
               </div>
            </div> -->
      <div class="spa-form-content-4 vh-100 d-flex align-items-center" id="scrollToGender">
        <div class="container">
          <div class="px-3">
            <h3 class="spa-gender">Select Gender</h3>
            <div>
              <span [class.highlight-btn]="FormValues.Gender == 'male'" (click)="selectedGender('male')"
                class="spa-yes-no">Male</span>
              <span [class.highlight-btn]="FormValues.Gender == 'female'" (click)="selectedGender('female')"
                class="spa-yes-no">Female</span>
            </div>
            <p class="error-msg" *ngIf="FormErrors != null && FormErrors.gender">*Please select your gender.</p>
          </div>
        </div>
      </div>

      <div class="spa-form-content-5 vh-100 d-flex align-items-center" id="scrollToSmoker">
        <div class="container">
          <div class="px-3">
            <h3 class="spa-smoke">Do you smoke?</h3>
            <div>
              <span [class.highlight-btn]="FormValues.Smoker == 'no'" (click)="isSomker('no')"
                class="spa-yes-no">NO</span>
              <span [class.highlight-btn]="FormValues.Smoker == 'yes'" (click)="isSomker('yes')"
                class="spa-yes-no">YES</span>
            </div>
            <p class="error-msg" *ngIf="FormErrors != null && FormErrors.smoker">*Please select an option.</p>
          </div>
        </div>
      </div>

      <div class="spa-form-content-6 vh-100 d-flex align-items-center" id="scrollToMedications">
        <div class="container">
          <div class="px-3">
            <h3 class="spa-medications">Do you have prescribed medications?</h3>
            <div>
              <span [class.highlight-btn]="FormValues.Medication == 'no'" (click)="mediCation('no')"
                class="spa-yes-no">NO</span>
              <span [class.highlight-btn]="FormValues.Medication == 'yes'" (click)="mediCation('yes')"
                class="spa-yes-no">YES</span>
            </div>
            <p class="error-msg" *ngIf="FormErrors != null && FormErrors.medication">*Please select an option.</p>
          </div>
        </div>
      </div>


      <div class="spa-form-content-7 vh-100 d-flex align-items-center" id="scrollToWH">
        <div class="container">
          <div class="px-3">
            <h3 class="spa-weight-height">Weight and Height</h3>
            <div class="weight-height-container">
              <input type="text" pattern="[0-9]*" class="spa-weight-input" (blur)="checkWeightHeight()" maxlength="3"
                (change)="FormValues.setWeight($event.target.value)" [value]="FormValues.getWeight()"
                placeholder="Weight lbs" name="weight">
              <select class="spa-height-feet" (change)="checkWeightHeight($event.target.value,'HeightFeet')"
                [(ngModel)]="FormValues.HeightInFeet" name="HeightInFeet">
                <option value="null">Feet</option>
                <option value="4">4 Feet</option>
                <option value="5">5 Feet</option>
                <option value="6">6 Feet</option>
                <option value="7">7 Feet</option>
              </select>
              <select class="spa-height-inch" (change)="checkWeightHeight($event.target.value,'HeightInch')"
                [(ngModel)]="FormValues.HeightInInch" name="HeightInInch">
                <option value="null">Inch</option>
                <option value="0">0 Inch</option>
                <option value="1">1 Inch</option>
                <option value="2">2 Inch</option>
                <option value="3">3 Inch</option>
                <option value="4">4 Inch</option>
                <option value="5">5 Inch</option>
                <option value="6">6 Inch</option>
                <option value="7">7 Inch</option>
                <option value="8">8 Inch</option>
                <option value="9">9 Inch</option>
                <option value="10">10 Inch</option>
                <option value="11">11 Inch</option>
              </select>
            </div>
            <p class="error-msg" *ngIf="FormErrors != null && FormErrors.height_and_weight">*Please enter your weight
              and height.</p>
          </div>
        </div>
      </div>

      <!-- <div class="spa-form-content-7 vh-100 d-flex align-items-center" id="scrollToHealth">
               <div class="container">
                  <div class="px-3 p-8rem">
                     <h3 class="health-info">Health Info</h3>
                     <div class="res-d-inline">
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Alcohol/Drugs
                              <input [checked]="checkHealthConditionExists('alcohol_drugs')" (change)="selectHealthCondition($event, 'alcohol_drugs')" type="checkbox" value="0">
                              <span class="spa-ccheckmark"></span>
                           </label>
                        </div>
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Crohn's Disease
                              <input [checked]="checkHealthConditionExists('crohn_disease')" (change)="selectHealthCondition($event, 'crohn_disease')" type="checkbox" value="1">
                              <span class="spa-ccheckmark"></span>
                           </label>
                        </div>
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Mental Illness
                              <input [checked]="checkHealthConditionExists('mental_illness')" (change)="selectHealthCondition($event, 'mental_illness')" type="checkbox" value="2">
                              <span class="spa-ccheckmark"></span>
                           </label>
                        </div>
                     </div>
                     <div class="res-d-inline">
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Alzheimer's Disease
                              <input [checked]="checkHealthConditionExists('alzheimer_disease')" (change)="selectHealthCondition($event, 'alzheimer_disease')" type="checkbox" value="0">
                              <span class="spa-ccheckmark"></span>
                            </label>
                        </div>
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Depression
                              <input [checked]="checkHealthConditionExists('depression')" (change)="selectHealthCondition($event, 'depression')" type="checkbox" value="1">
                              <span class="spa-ccheckmark"></span>
                           </label>
                        </div>
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Multiple Sclerosis
                              <input [checked]="checkHealthConditionExists('multiple_sclerosis')" (change)="selectHealthCondition($event, 'multiple_sclerosis')" type="checkbox" value="2">
                              <span class="spa-ccheckmark"></span>
                           </label>
                        </div>
                     </div>
                     <div class="res-d-inline">
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Asthma
                              <input [checked]="checkHealthConditionExists('asthma')" (change)="selectHealthCondition($event, 'asthma')" type="checkbox" value="0">
                              <span class="spa-ccheckmark"></span>
                            </label>
                        </div>
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Diabetes
                              <input [checked]="checkHealthConditionExists('diabetes')" (change)="selectHealthCondition($event, 'diabetes')" type="checkbox" value="1">
                              <span class="spa-ccheckmark"></span>
                           </label>
                        </div>
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Rheumatoid Arthritis
                              <input [checked]="checkHealthConditionExists('rheumatoid_arthritis')" (change)="selectHealthCondition($event, 'rheumatoid_arthritis')" type="checkbox" value="2">
                              <span class="spa-ccheckmark"></span>
                           </label>
                        </div>
                     </div>
                     <div class="res-d-inline">
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Anxiety Disorder
                              <input [checked]="checkHealthConditionExists('anxiety_disorder')" (change)="selectHealthCondition($event, 'anxiety_disorder')" type="checkbox" value="0">
                              <span class="spa-ccheckmark"></span>
                            </label>
                        </div>
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Epilepsy
                              <input [checked]="checkHealthConditionExists('epilepsy')" (change)="selectHealthCondition($event, 'epilepsy')" type="checkbox" value="1">
                              <span class="spa-ccheckmark"></span>
                           </label>
                        </div>
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Sleep Apnea
                              <input [checked]="checkHealthConditionExists('sleep_apnea')" (change)="selectHealthCondition($event, 'sleep_apnea')" type="checkbox" value="2">
                              <span class="spa-ccheckmark"></span>
                           </label>
                        </div>
                     </div>
                     <div class="res-d-inline">
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Basal Cell Skin Cancer
                              <input [checked]="checkHealthConditionExists('basal_cell_skin_cancer')" (change)="selectHealthCondition($event, 'basal_cell_skin_cancer')" type="checkbox" value="0">
                              <span class="spa-ccheckmark"></span>
                            </label>
                        </div>
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Emphysema
                              <input [checked]="checkHealthConditionExists('emphysema')" (change)="selectHealthCondition($event, 'emphysema')" type="checkbox" value="1">
                              <span class="spa-ccheckmark"></span>
                           </label>
                        </div>
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Stroke
                              <input [checked]="checkHealthConditionExists('stroke')" (change)="selectHealthCondition($event, 'stroke')" type="checkbox" value="2">
                              <span class="spa-ccheckmark"></span>
                           </label>
                        </div>
                     </div>
                     <div class="res-d-inline">
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Cancer
                              <input [checked]="checkHealthConditionExists('cancer')" (change)="selectHealthCondition($event, 'cancer')" type="checkbox" value="0">
                              <span class="spa-ccheckmark"></span>
                            </label>
                        </div>
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Heart Disease
                              <input [checked]="checkHealthConditionExists('heart_disease')" (change)="selectHealthCondition($event, 'heart_disease')" type="checkbox" value="1">
                              <span class="spa-ccheckmark"></span>
                           </label>
                        </div>
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Ulcerative Colitis or Ileitis
                              <input [checked]="checkHealthConditionExists('ulcerative_colitis_ileitis')" (change)="selectHealthCondition($event, 'ulcerative_colitis_ileitis')" type="checkbox" value="2">
                              <span class="spa-ccheckmark"></span>
                           </label>
                        </div>
                     </div>
                     <div class="res-d-inline">
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">COPD
                              <input [checked]="checkHealthConditionExists('copd')" (change)="selectHealthCondition($event, 'copd')" type="checkbox" value="0">
                              <span class="spa-ccheckmark"></span>
                            </label>
                        </div>
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Kidney or Liver Disease
                              <input [checked]="checkHealthConditionExists('kidney_liver_disease')" (change)="selectHealthCondition($event, 'kidney_liver_disease')" type="checkbox" value="1">
                              <span class="spa-ccheckmark"></span>
                           </label>
                        </div>
                        <div class="health-info-drug">
                           <label class="spa-chkbox-con">Vascular Disease
                              <input [checked]="checkHealthConditionExists('vascular_disease')" (change)="selectHealthCondition($event, 'vascular_disease')" type="checkbox" value="2">
                              <span class="spa-ccheckmark"></span>
                           </label>
                        </div>
                     </div>
                     <div class="res-d-inline">
                        <div class="">
                           <label class="spa-chkbox-con">None of the above applies to me
                              <input [checked]="checkHealthConditionExists('None')" (change)="selectHealthCondition($event, 'None')" type="checkbox" value="0">
                              <span class="spa-ccheckmark"></span>
                            </label>
                        </div>
                     </div>
                     <p class="error-msg" *ngIf="FormErrors != null && FormErrors.health_conditions">*Please select an option.</p>
                  </div>
               </div>
            </div> -->
      <div class="spa-form-content-5 vh-100 d-flex align-items-center" id="scrollToUserDetails">
        <div class="container">
          <div class="px-3">
            <!-- <h3 class="health-info">User Details</h3> -->
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 spa-user-details-input">
                <label>First Name</label>
                <input type="text" placeholder="First Name" name="first_name" class="spa-form-zip-input"
                  [(ngModel)]="FormValues.FirstName">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 spa-user-details-input">
                <label>Last Name</label>
                <input type="text" placeholder="Last Name" name="last_name" class="spa-form-zip-input"
                  [(ngModel)]="FormValues.LastName">
              </div>
              <p class="error-msg mb-100" *ngIf="FormErrors != null && FormErrors.name"
                style="margin-top: -30px;margin-left: 15px;">*Please enter your first name and last name.</p>

              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 spa-user-details-input mt-md-5 mt-0">
                <label>Email</label>
                <input type="email" placeholder="Email" name="email" class="spa-form-zip-input"
                  [(ngModel)]="FormValues.Email">
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 spa-user-details-input mt-md-5 mt-0">
                <label>Phone Number</label>
                <input type="tel" placeholder="Phone Number" name="phone_number" mask='(000) 000-0000'
                  onKeyPress="if(this.value.length==10) return false;" class="spa-form-zip-input"
                  [(ngModel)]="FormValues.PhoneNumber">
              </div>
              <p class="error-msg" *ngIf="FormErrors != null && FormErrors.contact_details"
                style="margin-top: -30px;margin-left: 15px;">*Please enter a valid email and phone number.</p>
            </div>
            <!-- <div class="spa-user-details-save px-3">
                    <button class="btn spa-save-btn" type="button">Save</button>
                  </div> -->
          </div>
          <div class="spa-form-submit px-3">
            <button class="btn spa-submit-btn
              mt-md-5 my-0" type="button" data-style="expand-right" [ladda]="isLoading" (click)="submitForm()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>


<div class="modal_qualify_overlay" id="qualifyModal">
  <div class="modal_qualify health-info-modal">
    <div class="modal_qualify_header
      d-flex
      justify-content-between">
      <h3 class="health-info my-0 py-3 px-4">Do you have any of these health conditions?</h3>

      <button class="btn btn__close close-btn-qualify-modal px-4 py-3" (click)="HeathQuestionModalClose()">
        <ion-icon name="close-outline"></ion-icon>
      </button>
    </div>

    <div class="modal_qualify_body health-info-modal">
      <form class="form_modal_qualify">
        <div class="px-3 ">
          <div class="res-d-inline">
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Alcohol/Drugs
                <input [checked]="checkHealthConditionExists('alcohol_drugs')"
                  (change)="selectHealthCondition($event, 'alcohol_drugs')" type="checkbox" value="0">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Crohn's Disease
                <input [checked]="checkHealthConditionExists('crohn_disease')"
                  (change)="selectHealthCondition($event, 'crohn_disease')" type="checkbox" value="1">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Mental Illness
                <input [checked]="checkHealthConditionExists('mental_illness')"
                  (change)="selectHealthCondition($event, 'mental_illness')" type="checkbox" value="2">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
          </div>
          <div class="res-d-inline">
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Alzheimer's Disease
                <input [checked]="checkHealthConditionExists('alzheimer_disease')"
                  (change)="selectHealthCondition($event, 'alzheimer_disease')" type="checkbox" value="0">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Depression
                <input [checked]="checkHealthConditionExists('depression')"
                  (change)="selectHealthCondition($event, 'depression')" type="checkbox" value="1">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Multiple Sclerosis
                <input [checked]="checkHealthConditionExists('multiple_sclerosis')"
                  (change)="selectHealthCondition($event, 'multiple_sclerosis')" type="checkbox" value="2">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
          </div>
          <div class="res-d-inline">
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Asthma
                <input [checked]="checkHealthConditionExists('asthma')"
                  (change)="selectHealthCondition($event, 'asthma')" type="checkbox" value="0">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Diabetes
                <input [checked]="checkHealthConditionExists('diabetes')"
                  (change)="selectHealthCondition($event, 'diabetes')" type="checkbox" value="1">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Rheumatoid Arthritis
                <input [checked]="checkHealthConditionExists('rheumatoid_arthritis')"
                  (change)="selectHealthCondition($event, 'rheumatoid_arthritis')" type="checkbox" value="2">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
          </div>
          <div class="res-d-inline">
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Anxiety Disorder
                <input [checked]="checkHealthConditionExists('anxiety_disorder')"
                  (change)="selectHealthCondition($event, 'anxiety_disorder')" type="checkbox" value="0">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Epilepsy
                <input [checked]="checkHealthConditionExists('epilepsy')"
                  (change)="selectHealthCondition($event, 'epilepsy')" type="checkbox" value="1">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Sleep Apnea
                <input [checked]="checkHealthConditionExists('sleep_apnea')"
                  (change)="selectHealthCondition($event, 'sleep_apnea')" type="checkbox" value="2">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
          </div>
          <div class="res-d-inline">
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Basal Cell Skin Cancer
                <input [checked]="checkHealthConditionExists('basal_cell_skin_cancer')"
                  (change)="selectHealthCondition($event, 'basal_cell_skin_cancer')" type="checkbox" value="0">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Emphysema
                <input [checked]="checkHealthConditionExists('emphysema')"
                  (change)="selectHealthCondition($event, 'emphysema')" type="checkbox" value="1">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Stroke
                <input [checked]="checkHealthConditionExists('stroke')"
                  (change)="selectHealthCondition($event, 'stroke')" type="checkbox" value="2">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
          </div>
          <div class="res-d-inline">
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Cancer
                <input [checked]="checkHealthConditionExists('cancer')"
                  (change)="selectHealthCondition($event, 'cancer')" type="checkbox" value="0">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Heart Disease
                <input [checked]="checkHealthConditionExists('heart_disease')"
                  (change)="selectHealthCondition($event, 'heart_disease')" type="checkbox" value="1">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Ulcerative Colitis or Ileitis
                <input [checked]="checkHealthConditionExists('ulcerative_colitis_ileitis')"
                  (change)="selectHealthCondition($event, 'ulcerative_colitis_ileitis')" type="checkbox" value="2">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
          </div>
          <div class="res-d-inline">
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">COPD
                <input [checked]="checkHealthConditionExists('copd')" (change)="selectHealthCondition($event, 'copd')"
                  type="checkbox" value="0">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Kidney or Liver Disease
                <input [checked]="checkHealthConditionExists('kidney_liver_disease')"
                  (change)="selectHealthCondition($event, 'kidney_liver_disease')" type="checkbox" value="1">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
            <div class="health-info-drug">
              <label class="spa-chkbox-con modal-check-con">Vascular Disease
                <input [checked]="checkHealthConditionExists('vascular_disease')"
                  (change)="selectHealthCondition($event, 'vascular_disease')" type="checkbox" value="2">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
          </div>
          <div class="res-d-inline">
            <div class="">
              <label class="spa-chkbox-con">None of the above
                <input [checked]="checkHealthConditionExists('None')" (change)="selectHealthCondition($event, 'None')"
                  type="checkbox" value="0">
                <span class="spa-ccheckmark"></span>
              </label>
            </div>
          </div>
          <p class="error-msg-black" *ngIf="FormErrors != null && FormErrors.health_conditions">*Please select an
            option.</p>
        </div>

        <div class="spa-form-submit-modal px-3">
          <button class="btn spa-submit-btn-modal spa-continue-btn-modal mb-3 mt-3" 
          data-style="expand-right" [ladda]="isLoading"
          (click)="continueNext()"
            type="button">
            Continue
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="progress-bar">
  <span class="steps" [class.active-step]="Step == 0"></span>
  <span class="steps" [class.active-step]="Step == 1"></span>
  <span class="steps" [class.active-step]="Step == 2"></span>
  <span class="steps" [class.active-step]="Step == 3"></span>
  <span class="steps" [class.active-step]="Step == 4"></span>
  <span class="steps" [class.active-step]="Step == 5"></span>
  <span class="steps" [class.active-step]="Step == 6"></span>
  <span class="steps" [class.active-step]="Step == 7"></span>
  <span class="steps" [class.active-step]="Step == 8"></span>
  <span class="steps" [class.active-step]="Step == 9"></span>
  <span class="steps" [class.active-step]="Step == 10"></span>
</div>
