import { Component, OnInit } from '@angular/core';
import { LeadFormModel } from '../../models/LeadForm';
import { QuoteService } from '../../services/quote.service';
import { MenuState } from '../../models/MenuState';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-apply',
  templateUrl: './apply.component.html',
  styleUrls: ['./apply.component.css']
})
export class ApplyComponent implements OnInit {
  public ApplyFormValue: ApplyFormModel = new ApplyFormModel;
  public active_popup: boolean = false;
  public loading: boolean = false;

  formSubmitted = false;
  public applyForm = this.formBuilder.group({
    full_name: ['', Validators.required],
    email: ['', [
      Validators.required,
      Validators.email
    ]],
    phone: ['', [
      Validators.required
    ]],
  });

  constructor(
    public FormValues: LeadFormModel = new LeadFormModel,
    private _QuoteService: QuoteService,
    public MenuStateValues: MenuState = new MenuState,
    private formBuilder: FormBuilder,
    private router: Router,
  ) { }

  ngOnInit() {
    window.scroll(0,0);
  }

  AddLead() {}
  /*
  apply() {
    this.formSubmitted = true;
    if (this.applyForm.valid) {
      this.applyForm.reset();
      this.formSubmitted = false;
    }
  }

  AddLead() {
    this.formSubmitted = true;
    if (this.applyForm.valid && !this.loading) {
      this.loading = true;
      let data = {
        // application data
        quote_guid: this.FormValues.GUID,

        ...this.applyForm.value,
  
        // Values from the Single Page Form
        gender: this.FormValues.Gender,
        tobacco: this.FormValues.Smoker,
        face_amount:this.FormValues.CoverageAmount,
        dob: this.FormValues.Dob,
        zip_code: this.FormValues.ZipCode,
        weightLbs: this.FormValues.Weight,
        heightFt: this.FormValues.HeightInFeet,
        heightIn: this.FormValues.HeightInInch,
        medications: this.FormValues.Medication,
        health_info : this.FormValues.HealthConditions
      };

      if(this.FormValues.HealthConditions.length == 0){
        delete data.health_info;
      }

      this._QuoteService.AddLead(data).subscribe(
        result => {
          if(result){
            // this.active_popup = true;
            this.router.navigate(['thank-you']);
            this.FormValues.ZipCode = null;
            this.FormValues.CoverageAmount = null;
            this.FormValues.Term = null;
            this.FormValues.Goal = null;
            this.FormValues.Gender  = null;
            this.FormValues.Smoker  = null; 
            this.FormValues.Medication  = null;
            this.FormValues.Dob  = null;
            this.FormValues.Citizen  = null;
            this.FormValues.Family  = null;
            this.FormValues.Weight  = null;
            this.FormValues.HeightInFeet  = null;
            this.FormValues.HeightInInch  = null;
            this.FormValues.HealthConditions = [];
            this.FormValues.GUID = null;
            this.applyForm.reset();
            this.formSubmitted = false;
            this.loading = false;
          }
        }
      );
    }
  }
  */
}

class ApplyFormModel {
  public Name:string;
  public Email:string;
  public Phone:string;
}