import { Injectable } from '@angular/core';

@Injectable()
export class DoIQualifyFormModel {
    public LeadId: string = null;
    public FullName: string = null;
    public Street: string = null;
    public City: string = null;
    public State: string = null;
    public ZipCode: string = null;
    public QuoteId: string = null;

    public QuestionsData: any[] = [];
    public Quote: any[] = [];
    public PolicyId: string = null;
    public CarrierId: any = null;
    public ProductId: string = null;

}