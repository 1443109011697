import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LeadFormModel } from '../../models/LeadForm';
import { MenuState } from '../../models/MenuState';
import { ShowResults } from '../../models/ShowResults';
import * as ouibounce from 'ouibounce';
import { QuoteService } from '../../services/quote.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DoIQualifyCarrier } from 'src/app/models/DoIQualifyCarrier';
import { DoIQualifyFormModel } from 'src/app/models/DoIQualifyForm';
import { AppGlobals } from 'src/app/models/AppGlobals';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {
  private spa_id:string = '5fb647faa348a';
  public isEditable: boolean = false;
  public QuoteResults: any[] = [];
  public loading: boolean = false;
  // public active_popup: boolean = false;
  public message: string = null;
  public QuotesGUID: any[] = [];
  public SendQuotesForm: SendQuotesFormValues = new SendQuotesFormValues;
  public FormValid: boolean = false;
  public FormErrors: any = {
                            "zip_code": false,
                            "dob": false,
                            "coverage_amount": false,
                            "gender": false,
                            "smoker": false,
                            "medication": false,
                            "weight": false,
                            "height_feet": false,
                            "height_inch": false,
                            "health_conditions": false,
                          };

  title = 'ng-ouibounce';
  @ViewChild('modal', {static: false}) modal: ElementRef;

  formSubmitted = false;
  public sendQuote = this.formBuilder.group({
    full_name: ['', Validators.required],
    email: ['', [
      Validators.required,
      Validators.email
    ]],
  });

  // ngAfterViewInit() {
  //   ouibounce(this.modal.nativeElement, { aggressive: true });
  // }

  constructor(
    private AppGlobals: AppGlobals,
    public FormValues: LeadFormModel = new LeadFormModel,
    public ResultShowValues: ShowResults = new ShowResults,
    public MenuStateValues: MenuState = new MenuState,
    private _QuoteService: QuoteService,
    private router: Router,
    private formBuilder: FormBuilder,
    public _DoIQualifyCarrier: DoIQualifyCarrier,
    public _DoIQualifyFormModel: DoIQualifyFormModel
  ) {
    // this.SaveLeadData();
  }

  ngOnInit() {
    window.scroll(0,0);
  }
  /*
  SaveLeadData() {
    let data = {
      __key: this.AppGlobals.spa_id,
      full_name: this.FormValues.FirstName + " " + this.FormValues.LastName,
      email: this.FormValues.Email,
      phone: this.FormValues.PhoneNumber,
      dob: this.FormValues.Dob,
      gender: this.FormValues.Gender,
      face_amount: this.FormValues.CoverageAmount,
      tobacco: this.FormValues.Smoker,
      zip_code: this.FormValues.ZipCode,
      weightLbs: this.FormValues.Weight,
      heightFt: this.FormValues.HeightInFeet,
      heightIn: this.FormValues.HeightInInch,
      medications: this.FormValues.Medication,
    }

    this.loading = true;

    this._QuoteService.AddLead(data).subscribe(
      result => {
        if(result['success']) {
          let data = result['data'];

          if(data != null) {
            this._DoIQualifyFormModel.FullName = data['lead_full_name'];
            this._DoIQualifyFormModel.City = data['city'];
            this._DoIQualifyFormModel.State = data['state'];
            this._DoIQualifyFormModel.ZipCode = data['zip_code'];
            this._DoIQualifyFormModel.LeadId = data['lead_id'];
          }
        }
      }
    );

    this.GetQuotes();
  }

  GetQuotes() {
    this.loading = true;

    let data = {
      gender: this.FormValues.Gender,
      tobacco: this.FormValues.Smoker,
      face_amount:this.FormValues.CoverageAmount,
      dob: this.FormValues.Dob,
      zip_code: this.FormValues.ZipCode,
      api_key: this.FormValues.ApiKey
    };

    this._QuoteService.GetQuote(data).subscribe(
      result => {
        if(result['message'] != undefined) {
          this.message = result['message'];
        }
        else {
          this.QuoteResults = result["data"];
        }
        this.loading = false;
      }
    );
  }

  SendRequest() {

    this.formSubmitted = true;
    if (this.sendQuote.valid) {
      this.loading = true;
      if(this.QuoteResults["message"] == undefined) {
        // push the values of the quotes
        let data = {
          save_quote_list: this.QuoteResults,

          // full_name: this.SendQuotesForm.name,
          // email: this.SendQuotesForm.email,
          ...this.sendQuote.value,

          gender: this.FormValues.Gender,
          tobacco: this.FormValues.Smoker,
          face_amount:this.FormValues.CoverageAmount,
          dob: this.FormValues.Dob,
          zip_code: this.FormValues.ZipCode,
          weightLbs: this.FormValues.Weight,
          heightFt: this.FormValues.HeightInFeet,
          heightIn: this.FormValues.HeightInInch,
          medications: this.FormValues.Medication,
          health_info : this.FormValues.HealthConditions
        };

        if(this.FormValues.HealthConditions.length == 0){
         delete data.health_info;
        }

        this._QuoteService.SendQuoteToMe(data).subscribe(
          result => {
            this.loading = true;
            // this.active_popup = true;
            this.FormValues.ZipCode = null;
            this.FormValues.CoverageAmount = null;
            this.FormValues.Term = null;
            this.FormValues.Goal = null;
            this.FormValues.Gender  = null;
            this.FormValues.Smoker  = null;
            this.FormValues.Medication  = null;
            this.FormValues.Dob  = null;
            this.FormValues.Citizen  = null;
            this.FormValues.Family  = null;
            this.FormValues.Weight  = null;
            this.FormValues.HeightInFeet  = null;
            this.FormValues.HeightInInch  = null;
            this.FormValues.HealthConditions = [];
            this.FormValues.GUID = null;
            this.sendQuote.reset();
            this.router.navigate(['thank-you']);
          }
        );
      }
    }
  }

  editFields() {
    this.isEditable = true;
  }

  editComplete() {
    this.FormErrors = this.validateForm();

    // check the form for error
    if(this.FormValid) {
      this.isEditable = false;
      this.GetQuotes();
    }
    else {

    }
  }

  apply(quote, carrier_id) {

    // this.FormValues.GUID = guid;
    this._DoIQualifyFormModel.Quote = quote;
    this._DoIQualifyFormModel.QuoteId = quote.quote_id;
    this._DoIQualifyCarrier.carrier_id = carrier_id;
    this._DoIQualifyFormModel.ProductId = quote.product_id;
    this._DoIQualifyFormModel.CarrierId = carrier_id;
    
    let data = {
      ...this._DoIQualifyFormModel,
      ...this.AppGlobals,
      'spa_id': this.spa_id
    };
    this._QuoteService.PolicyCreate(data).subscribe(
      result => {
        this._DoIQualifyFormModel.PolicyId = result['data']['policy_id'];
        this._DoIQualifyFormModel.CarrierId = result['data']['carrier'];

      }

    );


    // navigate to apply page
    this.router.navigate(['do-i-qualify']);
  }

  validateForm() {
    this.FormValid = true;
    let error: any = {
      "zip_code": false,
      "dob": false,
      "coverage_amount": false,
      "gender": false,
      "smoker": false,
      "medication": false,
      "weight": false,
      "height_feet": false,
      "height_inch": false,
      "health_conditions": false,

    };

    if(this.FormValues.ZipCode == null || this.FormValues.ZipCode == "") {
      this.FormValid = false;
      error.zip_code = true;
    }
    else {
      error.zip_code = false;
    }

    if(this.FormValues.Dob == null || this.FormValues.Dob == "") {
      this.FormValid = false;
      error.dob = true;
    }
    else {
      error.dob = false;
    }

    if(this.FormValues.CoverageAmount == null || this.FormValues.CoverageAmount == "") {
      this.FormValid = false;
      error.coverage_amount = true;
    }
    else {
      error.coverage_amount = false;
    }

    if(this.FormValues.Gender == null || this.FormValues.Gender == "") {
      this.FormValid = false;
      error.gender = true;
    }
    else {
      error.gender = false;
    }

    if(this.FormValues.Smoker == null || this.FormValues.Smoker == "") {
      this.FormValid = false;
      error.smoker = true;
    }
    else {
      error.smoker = false;
    }

    if(this.FormValues.Medication == null || this.FormValues.Medication == "") {
      this.FormValid = false;
      error.medication = true;
    }
    else {
      error.medication = false;
    }

    if(this.FormValues.Weight == null || this.FormValues.Weight == "") {
      this.FormValid = false;
      error.weight = true;
    }
    else {
      error.weight = false;
    }

    if(this.FormValues.HeightInFeet == null) {
      this.FormValid = false;
      error.height_feet = true;
    }
    else {
      error.height_feet = false;
    }

    if(this.FormValues.HeightInInch == null) {
      this.FormValid = false;
      error.height_inch = true;
    }
    else {
      error.height_inch = false;
    }

    return error;
  }
  */
}

class SendQuotesFormValues {
  public name: string;
  public email: string;
}
